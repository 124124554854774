/* eslint-disable  */
/*
 * Responsavel pelos conteudos recomentados do site
 * v1.0 */

import { fetch } from "whatwg-fetch";

(function () {
  window.RelatedPages = function (params) {
    params = params || { max_history: 100 };
    if (!window.cross_local_store) {
      console.error("script de cross-domain-local-storage.js nao foi incluido");
      return;
    }

    const getCategory = function (url) {
      /* Get category of url */
      const part = url.match(/app\/(.*?)\/[0-9]{4}/);
      if (part) {
        return part[1];
      }
      return window.categoria;
    };

    const save = function (hist) {
      if (!hist.indexOf) {
        hist = [];
      }
      const text = JSON.stringify(hist);
      window.cross_local_store.setLocalStorage("historyRelated", text);
    };
    return {
      hist: [],
      cats: null,
      load(callBack) {
        const _this = this;
        let iresp;
        window.cross_local_store.getLocalStorage("historyRelated", (resp) => {
          try {
            if (resp) {
              iresp = JSON.parse(resp);
              if (iresp.indexOf) {
                _this.hist = iresp;
              }
            }
          } catch (e) {
            _this.hist = [];
          }
          callBack();
        });
      },
      params,
      groupCategory() {
        let cats;
        /* Search for hits grouped by category */
        if (!this.cats) {
          cats = {};
          this.hist.forEach((item) => {
            let cat = null;
            if (typeof item === "string") {
              cat = getCategory(item);
            } else {
              cat = item.c;
            }
            if (cat) {
              if (!cats[cat]) {
                cats[cat] = 0;
              }
              cats[cat] += 1;
            }
          });
          this.cats = cats;
        }
        return this.cats;
      },
      getMaxCat() {
        const cats = this.groupCategory();
        let max = 0;
        let maxCat;
        let key;
        maxCat = null;
        for (key in cats) {
          if (cats.hasOwnProperty(key) && cats[key] > max) {
            maxCat = key;
            max = cats[key];
          }
        }
        return maxCat;
      },
      myCategory() {
        return getCategory(window.location.href);
      },
      getUrlsVisitadas() {
        const urlsVistas = [window.location.href];
        this.hist.forEach((e) => {
          try {
            if (e.u) {
              urlsVistas.push(e.u);
            } else {
              urlsVistas.push(e);
            }
          } catch (e) {
            urlsVistas.push(e);
          }
        });
        return urlsVistas;
      },
      addHistoryRelated() {
        const url = window.location.href;
        const cat = this.myCategory();
        let idx;
        if (cat) {
          idx = this.hist.indexOf(url);
          if (idx > -1) {
            this.hist.splice(idx, 1);
          }
          idx = 0;
          while (idx <= this.hist.length) {
            if (this.hist[idx] && this.hist[idx].u === url) {
              this.hist.splice(idx, 1);
            } else {
              idx++;
            }
          }
          this.hist.unshift({ u: url, c: cat });
          for (idx = this.hist.length; idx > this.params.max_history; idx--) {
            this.hist.pop();
          }
        }
        save(this.hist);
        return this.hist.length;
      },
      getSuggested(
        numMaisLidas,
        numCategoriaNoticia,
        numCategoriaFavorida,
        callback
      ) {
        let catPage;
        let catMinha;
        let tmpNoticia;
        let pageNumMaisLida = 3;
        let pageNumCategoriaLoc = 2;
        let pageNumCategoriaUsu = 3;
        let restante = 0;
        let idx;
        const urlsVistas = this.getUrlsVisitadas();

        catPage =
          (this.myCategory() || "")
            .split("-")
            .join(" ")
            .split("/")
            .slice(0, 2)
            .join("/") || "";
        catMinha =
          (this.getMaxCat() || "")
            .split("-")
            .join(" ")
            .split("/")
            .slice(0, 2)
            .join("/") || "";
        window.noticias_recomendadas = window.noticias_recomendadas || {
          mais_lidas: [],
        };
        window.noticias_recomendadas.listadas =
          window.noticias_recomendadas.listadas || [];
        // console.log('load_page_noticias step 3.1')
        const aas = document.getElementsByTagName("a");
        for (idx = 0; idx < aas.length; idx++) {
          const href = aas[idx].getAttribute("href");
          if (
            href &&
            window.noticias_recomendadas.listadas.indexOf(href) === -1
          ) {
            // window.noticias_recomendadas.listadas.push(href)
          }
        }
        const execResult = (callback) => {
          const listaNoticias = [];
          if (window.noticias_recomendadas) {
            pageNumMaisLida = numMaisLidas;
            pageNumCategoriaLoc = numCategoriaNoticia;
            pageNumCategoriaUsu = numCategoriaFavorida;

            // mais lidas
            for (
              idx = 0;
              idx < window.noticias_recomendadas.mais_lidas.length;
              idx++
            ) {
              tmpNoticia = window.noticias_recomendadas.mais_lidas.shift();
              if (
                tmpNoticia.url &&
                window.noticias_recomendadas.listadas.indexOf(
                  tmpNoticia.url
                ) !== -1
              ) {
                continue;
              }
              if (tmpNoticia !== undefined && tmpNoticia.url) {
                if (urlsVistas.indexOf(tmpNoticia.url) === -1) {
                  urlsVistas.push(tmpNoticia.url);
                  listaNoticias.push(tmpNoticia);
                  window.noticias_recomendadas.listadas.push(tmpNoticia.url);
                  pageNumMaisLida -= 1;
                } else {
                  window.noticias_recomendadas.mais_lidas.push(tmpNoticia);
                }
              }
              if (pageNumMaisLida < 1) {
                break;
              }
            }

            // da categoria da pagina
            if (
              window.noticias_recomendadas.ultimas &&
              catPage in window.noticias_recomendadas.ultimas
            ) {
              for (
                idx = 0;
                idx < window.noticias_recomendadas.ultimas[catPage].length;
                idx++
              ) {
                tmpNoticia =
                  window.noticias_recomendadas.ultimas[catPage].shift();
                if (
                  tmpNoticia.url &&
                  window.noticias_recomendadas.listadas.indexOf(
                    tmpNoticia.url
                  ) !== -1
                ) {
                  continue;
                }
                if (tmpNoticia !== undefined && tmpNoticia.url) {
                  if (urlsVistas.indexOf(tmpNoticia.url) === -1) {
                    urlsVistas.push(tmpNoticia.url);
                    listaNoticias.push(tmpNoticia);
                    window.noticias_recomendadas.listadas.push(tmpNoticia.url);
                    pageNumCategoriaLoc -= 1;
                  } else {
                    window.noticias_recomendadas.ultimas[catPage].push(
                      tmpNoticia
                    );
                  }
                }
                if (pageNumCategoriaLoc < 1) {
                  break;
                }
              }
            }

            // da categoria minha
            if (
              window.noticias_recomendadas.ultimas &&
              catMinha in window.noticias_recomendadas.ultimas
            ) {
              console.log(catMinha)
              for (
                idx = 0;
                idx < window.noticias_recomendadas.ultimas[catMinha].length;
                idx++
              ) {
                tmpNoticia =
                  window.noticias_recomendadas.ultimas[catMinha].shift();
                if (
                  window.noticias_recomendadas.listadas.indexOf(
                    tmpNoticia.url
                  ) !== -1
                ) {
                  continue;
                }
                if (tmpNoticia !== undefined && tmpNoticia.url) {
                  if (urlsVistas.indexOf(tmpNoticia.url) === -1) {
                    urlsVistas.push(tmpNoticia.url);
                    listaNoticias.push(tmpNoticia);
                    window.noticias_recomendadas.listadas.push(tmpNoticia.url);
                    pageNumCategoriaUsu -= 1;

                    console.log(listaNoticias)
                  } else {
                    window.noticias_recomendadas.ultimas[catMinha].push(
                      tmpNoticia
                    );
                  }
                }
                if (pageNumCategoriaUsu < 1) {
                  break;
                }
              }
            }

            // caso nao tenha encontrado o total para a pagina
            restante =
              pageNumMaisLida + pageNumCategoriaLoc + pageNumCategoriaUsu;
            if (restante > 0) {
              for (
                idx = 0;
                idx < window.noticias_recomendadas.mais_lidas.length;
                idx++
              ) {
                tmpNoticia = window.noticias_recomendadas.mais_lidas.shift();
                if (
                  tmpNoticia.url &&
                  window.noticias_recomendadas.listadas.indexOf(
                    tmpNoticia.url
                  ) !== -1
                ) {
                  continue;
                }
                if (tmpNoticia !== undefined && tmpNoticia.url) {
                  if (
                    tmpNoticia.url &&
                    urlsVistas.indexOf(tmpNoticia.url) === -1
                  ) {
                    urlsVistas.push(tmpNoticia.url);
                    listaNoticias.push(tmpNoticia);
                    window.noticias_recomendadas.listadas.push(tmpNoticia.url);
                    restante -= 1;
                  } else {
                    window.noticias_recomendadas.mais_lidas.push(tmpNoticia);
                  }
                }
                if (restante < 1) {
                  break;
                }
              }
            }

            if (restante > 0) {
              for (
                idx = 0;
                idx < window.noticias_recomendadas.mais_lidas.length;
                idx++
              ) {
                tmpNoticia = window.noticias_recomendadas.mais_lidas.shift();
                if (
                  window.noticias_recomendadas.listadas.indexOf(
                    tmpNoticia.url
                  ) !== -1
                ) {
                  continue;
                }
                if (tmpNoticia !== undefined && tmpNoticia.url) {
                  urlsVistas.push(tmpNoticia.url);
                  listaNoticias.push(tmpNoticia);
                  window.noticias_recomendadas.listadas.push(tmpNoticia.url);
                  restante -= 1;
                }
              }
            }
          }

          if (!callback) {
            return listaNoticias;
          }

          if (
            window.noticias_recomendadas.ultimas === undefined &&
            restante > 0
          ) {
            this.getSuggested(restante, 0, 0, (resp) => {
              callback(listaNoticias.concat(resp));
            });
          } else {
            callback(listaNoticias);
          }
        };

        if (
          window.noticias_recomendadas.mais_lidas.length === 0 &&
          window.noticias_recomendadas.ultimas === undefined
        ) {
          const API_RECOMENDADAS =
            "https://app2.uai.com.br/apps,59,40/get-noticias-recomendadas";
          fetch(API_RECOMENDADAS, { mode: "cors" })
            .then((response) => response.json())
            .then((result) => {
              const resp = result.ok;
              if (resp.ultimas) {
                const { listadas } = window.noticias_recomendadas;
                window.noticias_recomendadas = resp;
                window.noticias_recomendadas.listadas = listadas;
                execResult(callback);
              }
            })
            .catch((err) => {
              window.noticias_recomendadas.ultimas = [];
              // Alimenta base com estrutura da API para teste de carregamento de noticias
              if (window.fake_js) {
                const { listadas } = window.noticias_recomendadas;
                window.noticias_recomendadas = window.fake_js;
                window.noticias_recomendadas.listadas = listadas;
              }
              execResult(callback);
            });
        } else {
          return execResult(callback);
        }
      },
    };
  };
})();
