/* eslint-disable  */

export class Manager {
  static getHeaderTitle(element) {
    return element[0].getElementsByClassName("js-widget__header--title")[0];
  }

  static getHeaderChange(element) {
    return element[0].getElementsByClassName("js-widget__header--change")[0];
  }

  static getElementsDuplicate(news, query) {
    let duplicates = Array.from(news)
      .map((el) => {
        return el.querySelector(query).href;
      })
      .filter((element, index, self) => {
        return self.indexOf(element, index + 1) > -1;
      });

    return duplicates;
  }

  static replaceNews(element, idx, newSource) {
    let listPasta = newSource.pasta;
    let listCategoria = newSource.categoria;
    let headerTitle = newSource.chapeu === null ? listPasta : newSource.chapeu;
    let listImagem =
      element[idx].className.indexOf("news__big") != -1 ||
      element[idx].className.indexOf("news__medium") != -1
        ? newSource.imagem_grande || newSource.imagem
        : newSource.imagem;
    let listLink = newSource.link;
    let listTitulo = newSource.titulo;
    let listDescricao = newSource.descricao;

    let readLater = element[idx].querySelectorAll(
      ".js-news__header--read-later"
    );

    // Starts replace
    element[idx].setAttribute("data_pasta", listPasta);
    element[idx].dataset.src = listImagem;

    // Add icon forsub - loginwall, acesso=paywall, acesso=xxxwall...
    if (newSource.us_acesso && newSource.us_acesso.indexOf("wall") !== -1) {
      element[idx].classList.add("forsub");
    }

    // image
    let figure = element[idx].getElementsByTagName("figure")[0];
    if (!listImagem && figure) {
      figure.remove();
    }

    if (listImagem) {
      if (!figure) {
        let tmpFigure = `
          <div class="news__photo--img" style="background-image: url('${listImagem}');"></div>
          <a class="news__photo--link" href="${listLink}" title="${listTitulo}"></a>
        `;

        figure = document.createElement("figure");
        figure.classList.add("news__photo");
        figure.innerHTML = tmpFigure;
        element[idx].insertBefore(figure, element[idx].firstChild);
      }
      let newsPhotoImg = element[idx].querySelector("figure .news__photo--img");
      let newsPhotoLink = element[idx].querySelector(
        "figure .news__photo--link"
      );

      let newsPhotoImgTg = newsPhotoImg.querySelector("img");
      newsPhotoImgTg && newsPhotoImgTg.setAttribute("src", listImagem);
      newsPhotoImgTg && newsPhotoImgTg.setAttribute("srcset", listImagem);

      newsPhotoImg.style.backgroundImage = `url('${listImagem}')`;
      newsPhotoLink.setAttribute("href", listLink);
      newsPhotoLink.setAttribute("title", listTitulo);
      newsPhotoLink.dataset.titulo = listTitulo;

      if (!figure.classList.contains("photo-title-in")) {
        newsPhotoLink.innerHTML = listTitulo;
      }

      if (figure.classList.contains("photo-title-in")) {
        let newsFigureHeaderTitle = figure.querySelector(
          "figcaption .news__header--title"
        );
        let newsFigureTitleLink = figure.querySelector(
          "figcaption .news__title--link"
        );

        newsFigureHeaderTitle.innerHTML = headerTitle;
        newsFigureTitleLink.setAttribute("href", listLink);
        newsFigureTitleLink.setAttribute("title", listTitulo);
        newsFigureTitleLink.innerHTML = listTitulo;
      }
    }

    // read-later
    Array.from(readLater).forEach((el) => {
      el.dataset.image = listImagem;
      el.dataset.category = listCategoria;
      el.dataset.headerTitle = headerTitle;
      el.dataset.title = listTitulo;
      el.dataset.url = listLink;
    });

    // title and links
    let newsHeaderTitle = element[idx].querySelector(
      ".news__content .news__header--title"
    );
    let newsTitleLink = element[idx].querySelector(
      ".news__content .news__title--link"
    );
    let newsDescription = element[idx].querySelector(
      ".news__content .news__description"
    );

    newsHeaderTitle.innerHTML = headerTitle;
    newsTitleLink.setAttribute("href", listLink);
    newsTitleLink.setAttribute("title", listTitulo);
    newsTitleLink.innerHTML = listTitulo;

    if (newsDescription) newsDescription.innerHTML = listDescricao;
  }

  static removeVisibility(element) {
    element.classList.remove("show", "hidden");
  }

  static showElement(element) {
    if (element) {
      this.removeVisibility(element);
      element.classList.add("show");
    }
  }

  static showFlexElement(element) {
    if (element) {
      this.removeVisibility(element);
      element.classList.add("show-flex");
    }
  }

  static hiddenElement(element) {
    if (element) {
      this.removeVisibility(element);
      element.classList.add("hidden");
    }
  }

  static addCategoryNewsForYou(url) {
    let categories = [
      { noticias: "em.com.br" },
      { esportes: "superesportes.com.br" },
      { "saude-plena": "uai.com.br/app/noticia/saude" },
      { entretenimento: "uai.com.br" },
      { vrum: "estadodeminas.vrum.com.br" },
      { "lugar-certo ": "estadodeminas.lugarcerto.com.br" },
      { encontro: "revistaencontro.com.br" },
    ];

    var category = categories.filter((elem, i, array) => {
      let key = Object.keys(elem)[0];
      let value = elem[key];
      if (url.indexOf(value) !== -1) {
        return categories.indexOf(elem) === i;
      }
    });

    return Object.keys(category[0])[0];
  }

  static normalizeString(string) {
    string = string.replace(/^\s+|\s+$/g, ""); // trim
    string = string.toLowerCase();

    return string
      .split("")
      .map(
        function (letter) {
          let i = this.accents.indexOf(letter);
          return i !== -1 ? this.out.charAt(i) : letter;
        }.bind({
          accents:
            "ÀÁÂÃÄÅĄàáâãäåąßÒÓÔÕÕÖØÓòóôõöøóÈÉÊËĘèéêëęðÇĆçćÐÌÍÎÏìíîïÙÚÛÜùúûüÑŃñńŠŚšśŸÿýŽŻŹžżź·/_,:; ",
          out: "AAAAAAAaaaaaaaBOOOOOOOOoooooooEEEEEeeeeeeCCccDIIIIiiiiUUUUuuuuNNnnSSssYyyZZZzzz-------",
        })
      )
      .join("");
  }

  static validURL(myURL) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port
        "(\\?[;&amp;a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    );

    return pattern.test(myURL);
  }

  static isLocalhost() {
    return Boolean(
      window.location.hostname === "localhost" ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === "[::1]" ||
        // 127.0.0.1/8 is considered localhost for IPv4.
        window.location.hostname.match(
          /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
        )
    );
  }

  static scrollIt(
    destination,
    difference = 0,
    easing = "linear",
    duration = 800,
    callback
  ) {
    const easings = {
      linear(t) {
        return t;
      },
      easeInQuad(t) {
        return t * t;
      },
      easeOutQuad(t) {
        return t * (2 - t);
      },
      easeInOutQuad(t) {
        return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
      },
      easeInCubic(t) {
        return t * t * t;
      },
      easeOutCubic(t) {
        return --t * t * t + 1;
      },
      easeInOutCubic(t) {
        return t < 0.5
          ? 4 * t * t * t
          : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
      },
      easeInQuart(t) {
        return t * t * t * t;
      },
      easeOutQuart(t) {
        return 1 - --t * t * t * t;
      },
      easeInOutQuart(t) {
        return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t;
      },
      easeInQuint(t) {
        return t * t * t * t * t;
      },
      easeOutQuint(t) {
        return 1 + --t * t * t * t * t;
      },
      easeInOutQuint(t) {
        return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t;
      },
    };

    const start = window.pageYOffset;
    const startTime =
      "now" in window.performance ? performance.now() : new Date().getTime();

    const documentHeight = Math.max(
      document.body.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.clientHeight,
      document.documentElement.scrollHeight,
      document.documentElement.offsetHeight
    );
    const windowHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.getElementsByTagName("body")[0].clientHeight;
    const destinationOffset =
      typeof destination === "number"
        ? destination
        : destination.offsetTop - difference;
    const destinationOffsetToScroll = Math.round(
      documentHeight - destinationOffset < windowHeight
        ? documentHeight - windowHeight
        : destinationOffset
    );

    if ("requestAnimationFrame" in window === false) {
      window.scroll(0, destinationOffsetToScroll);
      if (callback) {
        callback();
      }
      return;
    }

    function scroll() {
      const now =
        "now" in window.performance ? performance.now() : new Date().getTime();
      const time = Math.min(1, (now - startTime) / duration);
      const timeFunction = easings[easing](time);
      window.scroll(
        0,
        Math.ceil(timeFunction * (destinationOffsetToScroll - start) + start)
      );

      if (window.pageYOffset === destinationOffsetToScroll) {
        if (callback) {
          callback();
        }
        return;
      }

      requestAnimationFrame(scroll);
    }

    scroll();
  }
}
