/* eslint-disable  */
import "./template-continue-lendo";
import "./continue-lendo";

import scrollLoadMore from "../shared/scroll-load-more";

const TARGET = document.getElementById("continue-lendo");

window.relatedPages = window.RelatedPages({
  max_history: window.max_history || 100,
});
window.relatedPages.load(() => {
  window.relatedPages.addHistoryRelated();
  if (TARGET) {
    scrollLoadMore(TARGET, window.load_page_noticias);
  }
});
