function scrollLoadMore(TARGET, ACTION, CALLBACK = null, OFFSET = 0) {
  window.addEventListener("scroll", () => {
    const offSetBottom = parseInt(window.pageYOffset + window.innerHeight);
    const offSetBottomTarget = parseInt(TARGET.offsetTop + TARGET.clientHeight);
    const offSetBottomOffset = offSetBottom + parseInt(OFFSET);

    if (offSetBottomOffset >= offSetBottomTarget) {
      if (!ACTION()) {
        CALLBACK;
      }
    }
  });
}

export default scrollLoadMore;
