import React, { memo } from "preact/compat";
import { h, render, Fragment } from "preact";
import { useState, useEffect, useMemo } from "preact/hooks";
import { tns } from "../../node_modules/tiny-slider/src/tiny-slider";

import replaceWord from "../utils/replace";

const fav = document.getElementById("fav");

function App() {
  let url = `https://www.uai.com.br/json/capauai/parceiros.json`;
  let hasItens = JSON.parse(localStorage.getItem("blogs"));

  const [blogsItem, setBlogsItem] = useState([]);
  const [newsItem, setNewsItem] = useState([]);
  const [favoritos, setFavoritos] = useState(hasItens);

  const [modal, setModal] = useState(false);
  // const [loading, setLoading] = useState(true);
  const [hasFavoritos, setHasFavoritos] = useState(false);

  const memorizedValue = useMemo(() => favoritos, [hasItens]);

  useEffect(() => {
    if (!hasItens || hasItens.length < 1) {
      setHasFavoritos(false);
    } else {
      setHasFavoritos(true);
      setFavoritos(hasItens);
    }

    fetch(url)
      .then((resp) => resp.json())
      .then((data) => {
        const blogs = Object.values(data.sites);
        const news = data.itens;

        let orderedBlogs = blogs.sort((item1, item2) => {
          if (item1.site) {
            var blogA = item1.site.titulo.toLowerCase();
            blogA = replaceWord(blogA);
          }

          if (item2.site) {
            var blogB = item2.site.titulo.toLowerCase();
            blogB = replaceWord(blogB);
          }

          return blogA > blogB ? 1 : blogB > blogA ? -1 : 0;
        });

        setBlogsItem(orderedBlogs);
        setNewsItem(news);
      })
      .catch((err) => {
        console.warn("Something went wrong.", err);
      });
  }, []);

  // useEffect(() => {

  // }, []);

  useEffect(() => {
    setFavoritos(favoritos);
  }, [memorizedValue]);

  function handleSetFavoritos() {
    const selectedBlogs = Array.from(
      document.querySelectorAll("input:checked[name=blog]")
    );

    let blogsFavoritos = selectedBlogs.map((blog) => {
      const blogsFavoritos = {
        name: blog.getAttribute("data-name"),
        portal: blog.getAttribute("portal"),
      };

      return blogsFavoritos;
    });

    localStorage.setItem("blogs", JSON.stringify(blogsFavoritos));
    setFavoritos(blogsFavoritos);

    if (blogsFavoritos.length < 1) {
      setHasFavoritos(false);
    } else {
      setHasFavoritos(true);
    }

    setModal(false);
  }

  async function slide() {
    setTimeout(() => {
      var slider = tns({
        container: ".fav-slide",
        items: 1,
        gutter: 31,
        center: true,
        edgePadding: 60,
        fixedWidth: 196,
        controls: false,
        navPosition: "bottom",
        loop: false,
        lazyload: true,
        useLocalStorage: true,
        onInit: function () {
          const favoritos = document.querySelectorAll(".card-favorito");

          favoritos.forEach((favorito) => favorito.classList.remove("visible"));
        },
        responsive: {
          320: {
            center: true,
            edgePadding: 10,
            gutter: 30,
            items: 2,
            fixedWidth: 196,
          },
          768: {
            center: false,
            edgePadding: 0,
            gutter: 20,
            items: 4,
            fixedWidth: 196,
          },
          1025: {
            center: false,
            edgePadding: 0,
            gutter: 20,
            items: 2,
            fixedWidth: false,
          },
          1280: {
            center: false,
            edgePadding: 0,
            gutter: 20,
            items: 3,
          },
        },
      });
    }, 1000);
  }

  function Standart() {
    return (
      <div class="standart">
        <div>
          <h2>Siga os canais favoritos</h2>
          <p>
            Novos posts dos seus canais favoritos irão aparecer com destaque na
            capa do Uai
          </p>
          <button class="btn" onClick={() => setModal(true)}>
            Escolher
          </button>
        </div>
      </div>
    );
  }

  function ModalBlogs() {
    return (
      <div className="modal_blogs">
        <div>
          <div className="header">
            <span>Meus Canais Favoritos</span>

            <button className="close-btn" onClick={handleSetFavoritos}>
              salvar
            </button>
          </div>
          <div className="content">
            <ul id="blogs" className="blog-content">
              {blogsItem.map((blog) => {
                let site = blog.site;
                if (site === undefined) {
                  site = blog.itens[0];
                  site.titulo = site.categoria;
                }

                return <BlogItem key={site.portal} site={site} />;
              })}
            </ul>
          </div>
        </div>
      </div>
    );
  }

  function BlogItem({ site }) {
    let check = "";

    if (favoritos) {
      favoritos.forEach((favorito) => {
        if (favorito.portal === site.portal) {
          check = "checked";
        }
      });
    }

    return (
      <li>
        <label for={site.portal} className="">
          {site.titulo}
        </label>
        <div className="blogs-btn">
          <input
            type="checkbox"
            className="checkbox"
            data-name={site.titulo}
            name="blog"
            portal={site.portal}
            checked={check}
          />
          <div className="knobs"></div>
          <div className="layer"></div>
        </div>
      </li>
    );
  }

  function NewsCard() {
    return (
      <Fragment>
        <div className="canais ">
          <div className="-header">
            <h2 className="">Canais favoritos</h2>
            <h2 id="loading"></h2>
            <button className="getBlogs desktop" onClick={() => setModal(true)}>
              adicionar +
            </button>

            <button
              type="button"
              className="favoritos section -trigger getBlogs mobile"
              onClick={() => setModal(true)}
            >
              <span className="trigger -ellipsis"></span>
              <span className="sr-only">Abrir menu</span>
            </button>
          </div>

          <div className="favoritados fav-slide">
            {favoritos.length === 1 ? (
              <Fragment>
                {blogsItem &&
                  blogsItem.map((news) => {
                    if (news.itens !== undefined) {
                      let blogNews = Object.values(news.itens);
                      return blogNews.map((blog) =>
                        favoritos.map((favorito) => {
                          if (favorito.portal === blog.portal) {
                            return (
                              <a
                                className="card-favorito visible"
                                portal={blog.portal}
                                href={blog.url}
                                key={blog.url}
                                target="_blank"
                                rel="noopener"
                              >
                                <span>
                                  <i className="icone-site"></i>
                                  {blog.categoria}
                                </span>
                                <figure>
                                  <img
                                    data-src={blog.imagem_196x110}
                                    src="https://www.uai.com.br/imgs/uai/v1-0-2/placeholder.svg"
                                    alt={blog.titulo}
                                    className="tns-lazy-img"
                                  />
                                  <figcaption>
                                    <p>{blog.titulo}</p>
                                  </figcaption>
                                </figure>
                              </a>
                            );
                          }
                        })
                      );
                    }
                  })}
              </Fragment>
            ) : (
              <Fragment>
                {newsItem.map((news) => {
                  return favoritos.map((favorito) => {
                    if (favorito.portal === news.portal) {
                      return (
                        <a
                          className="card-favorito visible"
                          portal={news.portal}
                          href={news.url}
                          key={news.url}
                          target="_blank"
                          rel="noopener"
                        >
                          <span>
                            <i className="icone-site"></i>
                            {news.categoria}
                          </span>
                          <figure>
                            <img
                              className="tns-lazy-img"
                              data-src={news.imagem_196x110}
                              src="https://www.uai.com.br/imgs/uai/v1-0-2/placeholder.svg"
                              alt={news.titulo}
                            />
                            <figcaption>
                              <p>{news.titulo}</p>
                            </figcaption>
                          </figure>
                        </a>
                      );
                    }
                  });
                })}
              </Fragment>
            )}
          </div>
          <span style="display: none;">{slide()}</span>
        </div>
      </Fragment>
    );
  }

  const MemoNewsCard = memo(NewsCard);

  if (modal) {
    return <ModalBlogs />;
  }

  return <Fragment>{hasFavoritos ? <MemoNewsCard /> : <Standart />}</Fragment>;
}

if (fav) {
  render(<App />, fav);
}
