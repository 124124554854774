import LazyLoad from "vanilla-lazyload";

// const myLazyLoad = new LazyLoad({
//   elements_selector: ".lazy",
//   threshold: 700,
//   callback_set: (ELEM) => {
//     ELEM.removeAttribute("data-src");
//   },
// });

const myLazyLoadTagImage = new LazyLoad({
  elements_selector: "[loading]",
  threshold: 700,
  data_src: "src",
  use_native: true,
  callback_set: (el) => {
    el.dataset.src && delete el.dataset.src;
    el.removeAttribute("data-src");
  },
  callback_enter: (el) => {
    el.dataset.callback && eval(el.dataset.callback);
  },
});
