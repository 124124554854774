/* eslint-disable  */
import { tns } from "../node_modules/tiny-slider/src/tiny-slider";

import "./favoritos/index.jsx";

import "./shared/localstorage-cross-domain";

function initialize() {
  const imgWithoutBackground = document.querySelectorAll(".-img");

  imgWithoutBackground.forEach((img) => {
    let src = img.getAttribute("src");
    if (src === "" || img.naturalHeight === 1) {
      img.setAttribute(
        "src",
        "https://www.uai.com.br/imgs/uai/v1-0-2/placeholder.svg"
      );
      img.style.objectFit = "contain";
    }
  });

  let daa = {
    // "base_url": "http://localhost:7000/api/v1",
    base_url: "https://contas.diariosassociados.com.br/api/v1",
    onChange: [],
  };

  daa.addEventListener = function (event, call) {
    if (event === "onChange") {
      daa["onChange"].push(call);
    }
  };

  daa.dispatchChange = function () {
    daa["onChange"].forEach(function (event) {
      event();
    });
  };

  daa.formatParams = function (params) {
    if (!params) {
      return "";
    }
    let items = Object.keys(params).map(function (key) {
      return key + "=" + encodeURIComponent(params[key]);
    });
    if (items.length === 0) {
      return "";
    }
    return "?" + items.join("&");
  };

  daa.getJSON = function (url, data, callback, callbackError, authorization) {
    let xhr = new XMLHttpRequest();
    xhr.open("GET", url + daa.formatParams(data), true);
    if (authorization) {
      xhr.setRequestHeader("Authorization", authorization);
    }
    xhr.setRequestHeader("Content-type", "application/json");
    xhr.onload = function () {
      let baseStatus = parseInt(parseInt(xhr.status) / 100);
      let resp = JSON.parse(xhr.responseText);
      if (baseStatus === 4) {
        callbackError(resp);
      } else {
        callback(resp);
      }
    };
    xhr.onerror = function (evt) {
      if (callbackError) {
        callbackError(evt);
      }
    };
    xhr.send();
  };

  daa.postJSON = function (url, data, callback, callbackError, authorization) {
    let xhr = new XMLHttpRequest();
    xhr.open("POST", url, true);
    if (authorization) {
      xhr.setRequestHeader("Authorization", authorization);
    }
    xhr.setRequestHeader("Content-type", "application/json");
    xhr.onload = function () {
      let baseStatus = parseInt(parseInt(xhr.status) / 100);
      let resp = JSON.parse(xhr.responseText);
      if (baseStatus === 4) {
        callbackError(resp);
      } else {
        callback(resp);
      }
    };
    xhr.onerror = function (evt) {
      if (callbackError) {
        callbackError(evt);
      }
    };
    xhr.send(JSON.stringify(data));
  };

  daa.clearDAA = function (noEvent) {
    window.localStorage.removeItem("DAA");
    // Nao estou no dominio centralizador entao devo registrar o central a autenticacao
    if (window.cross_local_store) {
      window.cross_local_store.setLocalStorage("DAA", "");
    }
    if (!noEvent) {
      daa.dispatchChange();
    }
  };

  daa.updateProducts = function (usu) {
    daa.getProducts(
      usu,
      function (data) {
        if ("products" in data) {
          let usu = daa.getDAA();
          usu["products"] = data["products"];
          daa.setDAA(usu);
        }
      },
      function (data) {
        if (data.status_code === 401) {
          daa.clearDAA();
        }
      }
    );
  };

  daa.updateServices = function (usu) {
    daa.getServices(
      usu,
      function (data) {
        if ("services" in data) {
          let usu = daa.getDAA();
          usu["services"] = data["services"];
          daa.setDAA(usu);
        }
      },
      function (data) {
        if (data.status_code === 401) {
          daa.clearDAA();
        }
      }
    );
  };

  daa.updateDAA = function (usu) {
    daa.getUser(
      usu,
      function (data) {
        if ("services" in data) {
          let usu = daa.getDAA();
          usu["services"] = data["services"];
          daa.setDAA(usu);
          daa.dispatchChange();
        }
      },
      function (data) {
        if (data.status_code === 401) {
          daa.clearDAA();
        }
      }
    );
  };

  daa.setDAA = function (data) {
    if (data["authorization"]) {
      data["ztime"] = new Date().getTime();
      // Nao estou no dominio centralizador entao devo registrar o central a autenticacao
      if (window.cross_local_store) {
        window.cross_local_store.setLocalStorage("DAA", JSON.stringify(data));
      }
      window.localStorage.setItem("DAA", JSON.stringify(data));
    }
  };

  daa.getDAA = function () {
    let data = window.localStorage.getItem("DAA");
    if (data) {
      let usu = JSON.parse(data);
      if (!usu) return null;
      let zdiff = new Date().getTime() - (usu.ztime || 0);
      if (zdiff > 3600000 * 6) {
        daa.updateDAA(usu);
        return usu;
      }
      return usu;
    }
    return null;
  };

  daa.isLogged = function () {
    return !!daa.getDAA();
  };

  daa.checkProduct = function (product) {
    let user = daa.getDAA();
    return (
      user &&
      user["authorization"] &&
      user["products"] &&
      user["products"].indexOf(product) > -1
    );
  };

  daa.checkService = function (idService) {
    let user = daa.getDAA();
    return (
      user &&
      user["authorization"] &&
      user["services"] &&
      user["services"].indexOf(idService) > -1
    );
  };

  daa.google_oauth2 = function (gtoken, callback, callbackError) {
    daa.postJSON(
      daa.base_url + "/account/login",
      { gtoken: gtoken },
      function (data) {
        daa.setDAA(data);
        daa.dispatchChange();
        if (callback) {
          callback(data);
        }
      },
      callbackError
    );
  };

  daa.signup = function (
    name,
    email,
    password,
    recaptcha,
    callback,
    callbackError
  ) {
    daa.postJSON(
      daa.base_url + "/account",
      {
        name: name,
        email: email,
        password: password,
        recaptcha: recaptcha,
      },
      function (data) {
        daa.setDAA(data);
        daa.dispatchChange();
        // Desloga com o google caso nao seja o mesmo e-mail.
        if (
          window.da_swg &&
          window.da_swg.google_user &&
          da_swg.google_user.email !== data.email
        ) {
          window.da_swg.logoff_google();
        }
        if (callback) {
          callback(data);
        }
      },
      callbackError
    );
  };

  daa.login = function (email, password, recaptcha, callback, callbackError) {
    daa.postJSON(
      daa.base_url + "/account/login",
      {
        email: email,
        password: password,
        recaptcha: recaptcha,
      },
      function (data) {
        daa.setDAA(data);
        daa.dispatchChange();
        // Desloga com o google caso nao seja o mesmo e-mail.
        if (
          window.da_swg &&
          window.da_swg.google_user &&
          da_swg.google_user.email !== data.email
        ) {
          window.da_swg.logoff_google();
        }
        if (callback) {
          callback(data);
        }
      },
      callbackError
    );
  };

  daa.recover_password = function (email, recaptcha, callback, callbackError) {
    daa.postJSON(
      daa.base_url + "/account/recover_password",
      {
        email: email,
        recaptcha: recaptcha,
        next: window.location.href,
      },
      callback,
      callbackError
    );
  };

  daa.getUser = function (usu, callback, callbackError) {
    daa.getJSON(
      daa.base_url + "/account",
      {},
      callback,
      callbackError,
      usu["authorization"]
    );
  };

  daa.getServices = function (usu, callback, callbackError) {
    daa.getJSON(
      daa.base_url + "/account/services",
      {},
      callback,
      callbackError,
      usu["authorization"]
    );
  };

  daa.getProducts = function (usu, callback, callbackError) {
    daa.getJSON(
      daa.base_url + "/account/products",
      {},
      callback,
      callbackError,
      usu["authorization"]
    );
  };

  daa.updateToDomainCenter = function () {
    if (
      !window.cross_local_store ||
      window.location.host.indexOf("www.em.com.br") === 0
    ) {
      return;
    }
    window.cross_local_store.getLocalStorage("DAA", (data) => {
      window.localStorage.setItem("DAA", data);
      window.daAccountsClient.dispatchChange();
    });
  };
  window.daAccountsClient = daa;

  /* global localStorage */
  /*
   * cross-domain-local-storage.js
   * Script para fazer Cross Domain em LocalStorage.
   * by slex@slex.com.br
   * version: v1.0
   * */

  window.CrossDomainLocalStorage = function (domainStorage, callBackOnLoad) {
    let _callBack = {};
    let domain = null;
    let iframe = null;
    if (domainStorage && domainStorage.indexOf(window.location.host) === -1) {
      domain = domainStorage.split("/").slice(0, 3).join("/");
      iframe = document.getElementById(domainStorage);
      if (!iframe) {
        iframe = document.createElement("iframe");
        iframe.src = domainStorage;
        iframe.setAttribute("id", domainStorage);
        iframe.setAttribute("src", domainStorage);
        iframe.setAttribute("frameborder", "0");
        iframe.style.setProperty("display", "none", "");
        iframe.style.setProperty("position", "absolute", "");
        iframe.style.setProperty("width", "0px", "");
        iframe.style.setProperty("height", "0px", "");
        iframe.style.setProperty("background-color", "transparent", "");
        iframe.addEventListener(
          "load",
          function () {
            if (callBackOnLoad) {
              callBackOnLoad();
            }
            iframe.setAttribute("loaded", "1");
          },
          true
        );
        document.body.appendChild(iframe);
      }
      let receiveMessage = function (e) {
        // console.log('receiveMessage')
        if (e && e.data && e.data.method) {
          // console.log('receiveMessage data')
          // console.log(e.data)
          // console.log(_callBack)
          if (e.data.method === "respGetLocalStorage" && _callBack) {
            if (
              e.data.parameter &&
              e.data.parameter.idcall &&
              _callBack[e.data.parameter.idcall]
            ) {
              // console.log('callback respGetLocalStorage')
              // console.log(e.data)
              _callBack[e.data.parameter.idcall](e.data.result);
              delete _callBack[e.data.parameter.idcall];
            }
          }
        }
      };
      window.addEventListener("message", receiveMessage);
    }
    let getLocalStorage = function (field, callBack) {
      if (domainStorage.indexOf(window.location.host) !== -1) {
        return callBack(localStorage.getItem(field));
      }
      let idcall = `_call${parseInt(Math.random() * Math.pow(10, 10))}`;
      // console.log(`register getLocalStorage -> ${field}`)
      _callBack[idcall] = callBack;
      let execute = function () {
        if (iframe && iframe.getAttribute("loaded")) {
          // console.log(`postMessage -> ${field}`)
          iframe.contentWindow["postMessage"](
            {
              method: "getLocalStorage",
              parameter: { field: field, idcall: idcall },
            },
            domain
          );
        } else {
          // console.log(`wainting -> ${field}`)
          window.setTimeout(execute, 300);
        }
      };
      execute();
    };
    let setLocalStorage = function (field, value) {
      if (domainStorage.indexOf(window.location.host) !== -1) {
        return localStorage.setItem(field, value);
      }
      let execute = function () {
        if (iframe && iframe.getAttribute("loaded")) {
          iframe.contentWindow["postMessage"](
            {
              method: "setLocalStorage",
              parameter: { field: field, value: value },
            },
            domain
          );
        } else {
          window.setTimeout(execute, 300);
        }
      };
      execute();
    };

    if (domainStorage.indexOf(window.location.host) !== -1) {
      window.setTimeout(function () {
        callBackOnLoad();
      }, 100);
    }
    return {
      getLocalStorage: getLocalStorage,
      setLocalStorage: setLocalStorage,
    };
  };

  window.cross_local_store = window.CrossDomainLocalStorage(
    "https://www.em.com.br/utils/cross-domain/"
  );

  if (document.querySelector(".tns-colunistas")) {
    var slider = tns({
      container: ".tns-colunistas",
      loop: false,
      slideBy: 1,
      responsive: {
        320: {
          gutter: 3,
          items: 1,
        },
      },
      controls: false,
      navPosition: "bottom",
    });
  }

  if (document.querySelector(".tns-filters")) {
    var slider = tns({
      container: ".tns-filters",
      items: 4,
      responsive: {
        640: {
          gutter: 10,
          items: 7,
        },
      },
      nav: false,
      gutter: 16,
      loop: true,
    });
  }

  if (document.querySelector(".tns-events")) {
    var slider = tns({
      container: ".tns-events",
      items: 1,
      gutter: 15,
      center: true,
      edgePadding: 40,
      responsive: {
        767: {
          center: false,
          edgePadding: 66,
          gutter: 33,
          items: 3,
        },
        1024: {
          center: false,
          edgePadding: 0,
          gutter: 33,
          items: 4,
        },
      },
      controls: false,
      navPosition: "bottom",
    });
  }

  if (document.querySelector("#especiais-carousel")) {
    var slider = tns({
      container: "#especiais-carousel",
      items: 1,
      gutter: 0,
      controls: false,
      navPosition: "bottom",
    });
  }

  if (document.querySelector(".tns-cinema")) {
    var slider = tns({
      container: ".tns-cinema",
      items: 1,
      gutter: 31,
      center: true,
      edgePadding: 60,
      responsive: {
        767: {
          center: false,
          edgePadding: 0,
          gutter: 32,
          items: 2,
        },
        991: {
          center: false,
          edgePadding: 0,
          gutter: 32,
          items: 4,
        },
      },
      controls: false,
      navPosition: "bottom",
    });
  }

  let locationUrl = window.location.host;

  if (locationUrl === "hom.uai.com.br") {
    locationUrl = "https://hom.uai.com.br";
  } else if (locationUrl === "dev.uai.com.br") {
    locationUrl = "http://dev.uai.com.br";
  } else {
    locationUrl = "https://uai.com.br";
  }

  // Altera as noticias das regioes
  let selected = Array.from(document.querySelectorAll("input[name=region]"));
  const title = document.querySelector(".region.section .-title .js-region");
  const customSlect = document.querySelector(".custom-select");

  selected.forEach((itemSelected) => {
    let checked = "regioes";

    itemSelected.addEventListener("change", () => {
      checked = itemSelected.getAttribute("id");
      if (checked === "regioes") {
        checked = "central";
      }

      customSlect.blur();
      regions(checked);
    });
  });

  function regions(region) {
    fetch(`https://www.uai.com.br/json/capauai/minas-gerais.json`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        return Promise.reject(response);
      })
      .then((data) => {
        const regions = document.querySelectorAll(".region.-grid .region.news");
        data[region].forEach((elem, index) => {
          if (regions.item(index)) {
            regions
              .item(index)
              .querySelector("a.news")
              .setAttribute("href", elem.url);
            regions
              .item(index)
              .querySelector(".-area").innerHTML = `<i class="icone-site"></i>${
              elem.categoria || elem.pasta
            }`;
            regions
              .item(index)
              .querySelector(".-img")
              .setAttribute("src", elem.imagem);
            regions.item(index).querySelector(".-title").textContent =
              elem.titulo;
            title.textContent = elem.regiao;
          }
        });
      })
      .catch((err) => {
        console.warn("Something went wrong.", err);
      });
  }

  regions("central");

  const buttons = document.querySelectorAll(".btn-js");

  const logos = document.querySelectorAll(".logo-js");

  const posters = document.querySelectorAll(".ad-js");

  function removeAttr() {
    buttons.forEach((elem) => elem.removeAttribute("active"));
  }

  function addVisibility() {
    posters.forEach((elem) => elem.classList.add("d-none"));
    logos.forEach((elem) => elem.classList.add("d-none"));
  }

  buttons.forEach((btn, position) => {
    btn.addEventListener("click", (event) => {
      if (event.currentTarget === btn) {
        removeAttr();
        btn.setAttribute("active", true);

        // mostra a div correspondente ao botão ativo
        addVisibility();
        logos.item(position).classList.remove("d-none");
        posters.item(position).classList.remove("d-none");
      }
    });
  });

  const searchBTN = document.querySelectorAll(".js-btn-search");
  const txt_search_header = document.querySelectorAll(".js-input-search");
  const btnList = Array.from(searchBTN);

  let isClose = true;

  btnList.forEach((element) => {
    const inputTEXT = element.parentNode.parentNode.querySelector(
      ".search-input .js-input-search"
    );

    element.addEventListener("click", (e) => {
      redirectSearch(inputTEXT);

      if (inputTEXT.value === "" && isClose) {
        inputTEXT.classList.remove("d-none");
        isClose = false;
      } else if (inputTEXT.value === "" && !isClose) {
        inputTEXT.classList.add("d-none");
        isClose = true;
      }

      inputTEXT.value = "";
    });
  });

  txt_search_header.forEach((txt_search) => {
    txt_search.addEventListener("keyup", (event) => {
      if (event.key === "Enter" && txt_search_header.value !== "") {
        console.log(txt_search);
        redirectSearch(txt_search);
      }
    });
  });

  function redirectSearch(text) {
    if (text.value.length >= 3) {
      let newurl = `${window.location.origin}/busca/?q=`;
      newurl += encodeURIComponent(text.value);
      window.location.assign(newurl);
    }
  }

  const trigger = document.querySelectorAll(".-trigger");
  const menus = document.querySelectorAll(".section.-menu");
  trigger.forEach((btn, position) => {
    btn.addEventListener("click", (event) => {
      const menu = event.currentTarget.nextElementSibling;
      menu.classList.toggle("-aberto");
    });
  });

  document.onclick = function (e) {
    if (!e.target.classList.contains("-trigger")) {
      menus.forEach((elem) => {
        elem.classList.remove("-aberto");
      });
    }

    if (!e.target.classList.contains("-search")) {
      txt_search_header.forEach((txt_search) => {
        txt_search.classList.add("d-none");
        isClose = true;
      });
    }
  };
  const jsTabs = document.querySelectorAll(".-js-tabs");

  function removeActiveClass() {
    jsTabs.forEach((elem) => elem.classList.remove("-active"));
  }

  function fetchData(region) {
    fetch("https://www.uai.com.br/json/capauai/tvalterosa.json")
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        return Promise.reject(response);
      })
      .then((data) => {
        const videos = document.querySelectorAll(".tv-alterosa a.news");

        data[region].itens.map((elem, index) => {
          videos
            .item(index)
            .querySelector(".news")
            .setAttribute("href", elem.url);
          videos.item(index).querySelector(".-area").textContent =
            elem.categoria;
          videos
            .item(index)
            .querySelector(".-img")
            .setAttribute(
              "src",
              elem.imagem
                ? elem.imagem
                : "https://www.alterosa.com.br/wp-content/themes/alterosa/assets/images/jpgs/alterosa_placeholder-medium.gif"
            );
          videos.item(index).querySelector(".-title").textContent = elem.titulo;
        });
      })
      .catch((err) => {
        console.warn("Something went wrong.", err);
      });
  }

  // fetchData("belo-horizonte");

  jsTabs.forEach((elem, position) => {
    elem.addEventListener("click", (event) => {
      if (event.currentTarget === elem) {
        const region = elem.getAttribute("data-region");
        removeActiveClass();
        jsTabs.item(position).classList.add("-active");
        console.log(region);
        fetchData(region);
      }
    });
  });

  const bars = document.querySelector(".menu.-bars");
  bars.addEventListener("click", () => {
    document.querySelector(".menu.-main").classList.toggle("-opened");
    document.querySelector("body").classList.toggle("no-scrolling");
  });

  const overlay = document.querySelector(".-js-overlay");
  overlay.addEventListener("click", () => {
    document.querySelector(".menu.-main").classList.toggle("-opened");
    document.querySelector("body").classList.toggle("no-scrolling");
  });

  const itemMenu = document.querySelectorAll(".menu .-item");
  itemMenu.forEach((item) => {
    item.addEventListener("click", () => {
      item.classList.toggle("-active");
    });

    if (item.children.length > 2) {
      item.querySelector("a").removeAttribute("href");
    }
  });

  // busca

  const search = document.querySelector(".js-filter-search");
  if (search) {
    const input = search.children[0];
    const select = search.children[1];
    const button = search.children[2];

    input.addEventListener("keyup", (e) => {
      if (e.key === "Enter" && search.children[1].value !== "") {
        redirectSearch(input);
      }
    });

    button.addEventListener("click", () => {
      let selected = Array.from(
        document.querySelectorAll("input[name=filters_search]")
      );

      if (select.value !== "") {
        selected.forEach((t) => {
          let checked = false;

          if (t == document.querySelector("input:checked")) {
            checked = t.getAttribute("id");

            if (checked === "todos") {
              checked = false;
            }
            redirectSearch(input, checked);
          }
        });
      }
    });

    function redirectSearch(text, term) {
      let filtro = `&portal=${term}`;

      if (text.value.length >= 3) {
        let newurl = `${window.location.origin}/busca/?q=`;
        newurl += encodeURIComponent(text.value) + `${term ? filtro : ""}`;
        window.location.assign(newurl);
      }
    }

    const searchResults = document.querySelector(".search-results ul");

    window.offset = 10;

    function newsItem(data) {
      let img = `<img src=${data.imagem} alt="${data.description}"/>`;
      return `
          <li>
            <a href="${data.url}" target="_blank">
              <figure class="entretenimento">
                ${data.imagem ? img : ""}
                <figcaption>
                  <span class="-area">${data.categoria}</span>
                  <p>${data.title}</p>
                </figcaption>
              </figure>
            </a>
          </li>`;
    }

    function appendResults(response) {
      const itens = response.news;
      let page = response.page;
      let limit = response.limit;

      window.offset = page * limit;

      itens.map((item) => {
        searchResults.insertAdjacentHTML("beforeend", newsItem(item));
      });
    }

    function request(limit) {
      let offset = +limit;
      let url = window.location.href;
      let formattedUrl = url.split("?q=", 2);
      let newUrl = formattedUrl.join("");

      let reqUrl =
        newUrl + "?json=63c055b-c8a7-4010-92c6-01803d6e752e&offset=" + offset;

      fetch(reqUrl)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          return Promise.reject(response);
        })
        .then((data) => appendResults(data));
    }

    let btnSearch = document.querySelector(".search-results button");

    btnSearch.addEventListener("click", () => {
      request(window.offset);
    });
  }
}

document.addEventListener("DOMContentLoaded", initialize);
