export default function replaceWord(str) {
  str = str.replace(/[àáâãäå]/, "a");
  str = str.replace(/[éèëê]/, "e");
  str = str.replace(/[íìïî]/, "i");
  str = str.replace(/[óòõöô]/, "o");
  str = str.replace(/[úùüû]/, "u");

  // o resto

  return str.replace(/[^a-z0-9]/gi, " ");
}
