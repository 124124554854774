/* global localStorage */
/*
 * cross-domain-local-storage.js
 * Script para fazer Cross Domain em LocalStorage.
 * by slex@slex.com.br
 * version: v1.0
 * */

window.CrossDomainLocalStorage = function (domainStorage, callBackOnLoad) {
  const _callBack = {};
  let domain = null;
  let iframe = null;
  if (domainStorage && domainStorage.indexOf(window.location.host) === -1) {
    domain = domainStorage.split("/").slice(0, 3).join("/");
    iframe = document.getElementById(domainStorage);
    if (!iframe) {
      iframe = document.createElement("iframe");
      iframe.src = domainStorage;
      iframe.setAttribute("id", domainStorage);
      iframe.setAttribute("src", domainStorage);
      iframe.setAttribute("frameborder", "0");
      iframe.style.setProperty("display", "none", "");
      iframe.style.setProperty("position", "absolute", "");
      iframe.style.setProperty("width", "0px", "");
      iframe.style.setProperty("height", "0px", "");
      iframe.style.setProperty("background-color", "transparent", "");
      iframe.addEventListener(
        "load",
        () => {
          if (callBackOnLoad) {
            callBackOnLoad();
          }
          iframe.setAttribute("loaded", "1");
        },
        true
      );
      document.body.appendChild(iframe);
    }
    const receiveMessage = function (e) {
      // console.log('receiveMessage')
      if (e && e.data && e.data.method) {
        // console.log('receiveMessage data')
        // console.log(e.data)
        // console.log(_callBack)
        if (e.data.method === "respGetLocalStorage" && _callBack) {
          if (
            e.data.parameter &&
            e.data.parameter.idcall &&
            _callBack[e.data.parameter.idcall]
          ) {
            // console.log('callback respGetLocalStorage')
            // console.log(e.data)
            _callBack[e.data.parameter.idcall](e.data.result);
            delete _callBack[e.data.parameter.idcall];
          }
        }
      }
    };
    window.addEventListener("message", receiveMessage);
  }
  const getLocalStorage = function (field, callBack) {
    if (domainStorage.indexOf(window.location.host) !== -1) {
      return callBack(localStorage.getItem(field));
    }
    const idcall = `_call${parseInt(Math.random() * Math.pow(10, 10))}`;
    // console.log(`register getLocalStorage -> ${field}`)
    _callBack[idcall] = callBack;
    const execute = function () {
      if (iframe && iframe.getAttribute("loaded")) {
        // console.log(`postMessage -> ${field}`)
        iframe.contentWindow.postMessage(
          { method: "getLocalStorage", parameter: { field, idcall } },
          domain
        );
      } else {
        // console.log(`wainting -> ${field}`)
        window.setTimeout(execute, 300);
      }
    };
    execute();
  };
  const setLocalStorage = function (field, value) {
    if (domainStorage.indexOf(window.location.host) !== -1) {
      return localStorage.setItem(field, value);
    }
    const execute = function () {
      if (iframe && iframe.getAttribute("loaded")) {
        iframe.contentWindow.postMessage(
          {
            method: "setLocalStorage",
            parameter: { field, value },
          },
          domain
        );
      } else {
        window.setTimeout(execute, 300);
      }
    };
    execute();
  };

  if (domainStorage.indexOf(window.location.host) !== -1) {
    window.setTimeout(() => {
      callBackOnLoad();
    }, 100);
  }
  return {
    getLocalStorage,
    setLocalStorage,
  };
};

window.cross_local_store = window.CrossDomainLocalStorage(
  "https://www.em.com.br/utils/cross-domain/"
);
