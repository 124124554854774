/* eslint-disable  */
import { Manager } from "../utils/manager";

window.loading_page_noticias = false;

(() => {
  window.load_page_noticias = function () {
    if (window.loading_page_noticias) {
      return false;
    }
    window.loading_page_noticias = true;
    const numMaisLidas = 3;
    const numCategoriaNoticia = 2;
    const numCategoriaFavorida = 3;
    window.relatedPages.getSuggested(
      numMaisLidas,
      numCategoriaNoticia,
      numCategoriaFavorida,
      (resp) => {
        window.loading_page_noticias = false;
        if (!resp || !resp.length === 0) {
          return false;
        }

        const continueLendo = document.getElementById("continue-lendo");
        const newsBox = continueLendo.querySelector(".news__box");
        const lengthItems = newsBox.children.length;
        const tmpNoticia = `
          <a  href="" class="news -link" title="">
            <figure class="">
              <figcaption class="news -area"></figcaption>
              <img class="news -img"  loading="lazy" src="assets/img/placeholder.svg" data-src="assets/img/placeholder.svg" alt="" />
              <figcaption>
                <p class="news -title"> </p>
              </figcaption>
            </figure>
          </a>`;
        const tmpPublicidade = `
          <div class="advertising__with-bg mt-24" style="min-height: 316px;">
            <img src="https://imgs2.uai.com.br/UAI_2018/placeholders/300x250.gif" alt="">
          </div>
        `;

        const random = Math.ceil(Math.random() * (8 - 5) + 5);
        resp.length && resp.splice(random, 0, { tipo: "publicidade" });
        resp.map((item, key) => {
          const {
            titulo,
            data,
            url,
            imagem,
            pasta,
            imagem_330x220,
            descricao,
            us_acesso,
          } = item;

          console.log('resp ' + pasta);
          if (item.tipo === "publicidade") {
            if (window.slotdfpev && window.slotdfpev.buildAds) {
              const newsPublicidade = document.createElement("div");

              // Add class box
              newsPublicidade.classList.add("news");
              newsPublicidade.classList.add("-small");
              newsPublicidade.classList.add("ad");
              newsPublicidade.innerHTML = tmpPublicidade;
              newsBox.append(newsPublicidade);

              try {
                window.slotdfpev.buildAds(newsPublicidade);
              } catch (e) {}
            }
            return false;
          }
          const keyItem = lengthItems + (key + 1);
          const news = document.createElement("article");

          // Add class box
          news.classList.add("news");
          news.classList.add("-img");
          news.innerHTML = tmpNoticia;

          // Add icon forsub - loginwall, acesso=paywall, acesso=xxxwall...
          if (us_acesso && us_acesso.indexOf("wall") !== -1) {
            const article = news.querySelector("article");
            article.classList.add("forsub");
          }

          // Add date
          // const newsData = news.querySelector(".label-data");
          // newsData.innerHTML = data;

          // Add info of link
          const newsLink = news.querySelector(".news.-link");
          newsLink.setAttribute("href", url);
          newsLink.setAttribute(
            "onclick",
            `eventoGA('veja mais','mais noticias', '${keyItem}')`
          );

          const newsTitle = news.querySelector(".news.-title");
          newsTitle.innerHTML = titulo;

          // add category
          const category = Manager.addCategoryNewsForYou(url);
          const newsContent = news.querySelector(".news.-area");
          newsContent.classList.add(category);
          newsContent.innerHTML = `<i class="icone-site"></i> ${
            pasta.split("/")[1]
          }`;

          // add info of image
          const newsPhotoImg = news.querySelector(".news.-img");
          newsPhotoImg.setAttribute("alt", titulo);
          if (imagem) {
            const src = imagem_330x220 || imagem;
            newsPhotoImg.setAttribute("src", src);
          } else {
            newsPhotoImg.setAttribute(
              "src",
              "https://www.uai.com.br/imgs/uai/v1-0-2/placeholder.svg"
            );
          }
          newsBox.append(news);
        });
      }
    );
  };
})();
